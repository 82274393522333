<template>

  <content-with-sidebar class="blog-wrapper" v-loading="loading" >

    <!-- blogs -->
    <b-row class="blog-list-wrapper" >'
      <b-col>
   <b-card title="O'yinlar ro'yhati">
     <b-table
         hover
         responsive="sm"
         :items="items"
         :fields="fields"
         :current-page="currentPage"
     >

       <template v-slot:cell(hashId)="data">
         <b-avatar
             :src="`https://bolajon.mtsh.uz/api/file-storages/preview/${data.value}/avatar.jpg`" alt=""
             class="mx-1"
         />
       </template>
       <template v-slot:cell(contentUz)="data">
         <div v-html="data.value"></div>
       </template>
       <template v-slot:cell(contentRu)="data">
         <div v-html="data.value"></div>
       </template>
       <template v-slot:cell(contentEn)="data">
         <div v-html="data.value"></div>
       </template>
       <template #cell(editButton)="data">
         <div class="edit-button-container cursor-pointer" @click="editeGameList(data.item.id)">
           <feather-icon
               icon="EditIcon"
               class="mr-75"
               size="18"
           />
           Tahrirlash
         </div>
       </template>
       <template #cell(infoButton)="data">
         <div class="edit-button-container cursor-pointer" @click="infoGamesList(data.item.id)">
           <feather-icon
               icon="ArrowRightCircleIcon"
               class="mr-75"
               size="18"
           />
           Batafsil
         </div>
       </template>
     </b-table>
   </b-card>
      </b-col>
<!--      <b-col-->
<!--        v-for="blog in blogList"-->
<!--        :key="blog.hashId"-->
<!--        md="4"-->
<!--      >-->
<!--        <b-card-->
<!--          tag="article"-->
<!--          no-body-->
<!--          class="custom-card"-->
<!--        >-->
<!--          <b-link :to="{ name: 'game-blog-detail', params: { id: blog.id } }">-->
<!--            <b-img-->
<!--                :src="`/api/file-storages/preview/${blog.hashId}/avatar.jpg`"-->
<!--                class="card-img-top"-->
<!--            />-->
<!--          </b-link>-->
<!--          <b-card-body>-->
<!--            <b-card-title>-->
<!--              <b-link-->
<!--                  :to="{ name: 'game-blog-detail', params: { id: blog.id } }"-->
<!--                  class="blog-title-truncate text-body-heading"-->
<!--              >-->
<!--                {{ blog.titleUz }}-->
<!--              </b-link>-->
<!--            </b-card-title>-->

<!--            <div class="my-1 py-25">-->
<!--              <b-link-->

<!--              >-->
<!--                <b-badge-->
<!--                  pill-->
<!--                  class="mr-75"-->
<!--                  :variant="tagsColor(blog.type)"-->
<!--                >-->
<!--                <span v-if="blog.type ==='IJTIMOIY'">Ijtimoiy-emotsional</span>-->
<!--                <span v-if="blog.type ==='KOGNITIV'">Kognitiv</span>-->
<!--                <span v-if="blog.type ==='TIL'">Til va aloqa</span>-->
<!--                <span v-if="blog.type ==='MOTORIKA'">Motorika</span>-->

<!--                </b-badge>-->
<!--              </b-link>-->
<!--            </div>-->
<!--            <hr>-->
<!--            <div class="d-flex justify-content-between align-items-center">-->
<!--              <b-link-->
<!--                :to="{ name: 'game-blog-detail', params: { id: blog.id } }"-->
<!--                class="font-weight-bold"-->
<!--              >-->
<!--                Batafsil-->
<!--              </b-link>-->
<!--            </div>-->
<!--          </b-card-body>-->
<!--        </b-card>-->
<!--      </b-col>-->
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              align="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
          />
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Qidirish"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->

      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Kategoriyalar
        </h6>
        <div v-for="category in adviceCategoryTypes" :key="category.value" class="d-flex justify-content-start align-items-center mb-75">
          <b-link @click="selectCategory(category)">
            <b-avatar rounded size="32" :variant="tagsColor(category.value)" class="mr-75">
              <feather-icon :icon="category.icon" size="16" />
            </b-avatar>
          </b-link>
          <b-link @click="selectCategory(category)">
            <div class="blog-category-title text-body">
              {{ category.text }}
            </div>
          </b-link>
        </div>


      </div>
      <!--/ categories -->
    </div>

    <!--/ sidebar -->
  </content-with-sidebar>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import spinner from '@/views/components/spinner/Spinner.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BTable,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    spinner,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      loading: false,
      search_query: '',
      blogList: [],
      items: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'hashId', label: 'Rasm', sortable: true },
        { key: 'titleUz', label: 'Sarlavhasi', sortable: true },
        { key: 'contentUz', label: 'Kontent Uz', sortable: true },
        { key: 'contentRu', label: 'Kontent Ru', sortable: true },
        { key: 'contentEn', label: 'Kontent Eng', sortable: true },
        { key: 'editButton', label: 'Tahrirlash' },
        { key: 'infoButton', label: 'Batafsil' },
      ],
      category: {
        type: '',
      },
      adviceCategoryTypes: [
        { value: 'IJTIMOIY', text: 'Ijtimoiy-emotsional',icon:'OctagonIcon'  },
        { value: 'KOGNITIV', text: 'Kognitiv' ,icon:'RssIcon' },
        { value: 'TIL', text: 'Til va aloqa' ,icon:'CodeIcon' },
        { value: 'MOTORIKA', text: 'Motorika' ,icon:'MoveIcon' },
        { value: null, text: 'Barchasi',icon:'RefreshCcwIcon'},
      ],
      blogSidebar: {},
      selectedCategory: null,

      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      typeBlog: {
        "ageCategoryId": null,
        "type": null,
      },
    }
  },
  watch:{
    currentPage() {
      this.getGames();
    },
  },
  created() {
    this.getGames();
  },
  methods: {
    infoGamesList(id){
      this.$router.push({
        name: 'game-blog-detail',
        params: { id: id },
      })
    },
    editeGameList(id){
      this.$router.push({
        name: 'game',
        params: { id: id },
      })
    },
    selectCategory(category) {
      this.selectedCategory = category.value;
      this.typeBlog.type = this.selectedCategory;
      this.getGames();
    },
    getGames() {
      this.loading = true;
      const currentPage = this.currentPage -1;
      this.$http
          .post(`/game/find-all?page=${currentPage}&size=${this.perPage}`, this.typeBlog)
          .then(response => {
            this.totalRows = response.data.totalElements;
            this.blogList = response.data.content;
            this.items = response.data.content;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          })
          .catch(error => {
            console.error('Axios error:', error)
          })
    },
    kFormatter,
    tagsColor(tag) {
      switch (tag) {
        case 'IJTIMOIY':
          return 'light-primary';
        case 'KOGNITIV':
          return 'light-danger';
        case 'TIL':
          return 'light-secondary';
        case 'MOTORIKA':
          return 'light-success';
      }
    },


  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
.custom-card {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.img-size{
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
}
</style>
